// UI
import H1 from '../../UI/Titles/H1';
import H4 from '../../UI/Titles/H4';
import Button from '../../UI/Button';
// Styled
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  text-align: center;
`;

const Head1 = styled(H1)`
  margin: 0 auto;
`;

const Head4 = styled(H4)`
  margin: 55px auto 0;
  max-width: 635px;

  @media (max-width: ${tablet}) {
    margin: 40px auto 0;
  }
`;

const Text = styled(ReactMarkdown)`
  ${Typography.richText};
  margin: 55px auto 0;
  max-width: 750px;

  @media (max-width: ${tablet}) {
    margin: 40px auto 0;
  }
`;

const Map = styled.div`
  width: 100%;
  margin: 55px auto 0;
  border-radius: 25px;
  overflow: hidden;

  img {
    display: block;
  }

  @media (max-width: ${tablet}) {
    margin: 40px auto 0;
    width: 100%;
    max-width: 560px;

    img {
      height: 260px;
    }
  }
`;

const Btn = styled(Button)`
  max-width: 365px;
  width: 100%;
  margin-top: 55px;

  @media (max-width: ${tablet}) {
    margin-top: 40px;
  }
`;

export {
  Wrapper,
  Head1,
  Head4,
  Text,
  Map,
  Btn,
}