import React from 'react';
import PropTypes from 'prop-types';
// Components
import Container from '../../layouts/Container';
import NearMeInfoItem from './components/NearMeInfoItem';
// Style
import {
  Wrapper,
} from './styled';

const NearMeInfo = ({ data }) => {
  return (
    <Wrapper>
      <Container>
        {data.map((info, index) => <NearMeInfoItem data={info} key={info.id} isreverse={(index % 2) === 0} /> )}
      </Container>
    </Wrapper>
  );
};

NearMeInfo.propTypes = {
  data: PropTypes.array.isRequired,
};

export default NearMeInfo;
