import ReactMarkdown from 'react-markdown';
// Styled
import styled from 'styled-components';
import Colors from '../../../../../assets/styles/Colors';
import Typography from '../../../../../assets/styles/Typography';
import { tablet } from "../../../../../assets/styles/Responsive";
// UI
import H2 from '../../../../UI/Titles/H2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isreverse ? 'row-reverse' : 'row'};
  margin-bottom: 110px;
  
  &:last-child {
    margin-bottom: 0 !important;
  }

  @media (max-width: ${tablet}) {
    display: block;
    margin-bottom: 75px;
  }
`;

const Description = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${props => props.isreverse ? '0 90px' : '0 150px 0 0'};

  @media (max-width: ${tablet}) {
    padding: 0;
    text-align: center;
  }
`;

const Head2 = styled(H2)`
  color: ${Colors.aqua};
`;

const Text = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 40px;
  color: ${Colors.aqua};
`;

const Media = styled.div`
  width: 51%;
  flex-shrink: 0;

  @media (max-width: ${tablet}) {
    margin: 65px auto 0;
    max-width: 560px;
    width: 100%;
  }
`;

const MediaCard = styled.div`
  border-radius: 25px;
  overflow: hidden;
  background-color: ${Colors.shadowGreen};
`;

export {
  Wrapper,
  Description,
  Head2,
  Text,
  Media,
  MediaCard,
}