import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// Layout
import MainLayout from '../components/layouts/MainLayout';
// Components
import Seo from "../components/templates/Seo/seo";
import NearMeMap from '../components/templates/NearMeMap';
import NearMeInfo from '../components/templates/NearMeInfo';
import FaqBanner from '../components/templates/FaqBanner';

const FaqPage = () => {
  const {
    seo,
    map,
    nearMeInfo,
    faqSection,
    shopNowBanner,
  } = NearMeQuery();

  const SEOSchema = {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://torontocannabisauthority.ca/#website","url":"https://torontocannabisauthority.ca/","name":"TORONTO CANNABIS AUTHORITY","description":"We\u2019ve Got The Best Weed in Toronto!","potentialAction":[{"@type":"SearchAction","target":"https://torontocannabisauthority.ca/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/near-me/#webpage","url":"https://torontocannabisauthority.ca/near-me/","name":"Near me - TORONTO CANNABIS AUTHORITY","isPartOf":{"@id":"https://torontocannabisauthority.ca/#website"},"datePublished":"2021-05-17T16:41:32+00:00","dateModified":"2021-05-17T16:47:37+00:00","breadcrumb":{"@id":"https://torontocannabisauthority.ca/near-me/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://torontocannabisauthority.ca/near-me/"]}]},{"@type":"BreadcrumbList","@id":"https://torontocannabisauthority.ca/near-me/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/","url":"https://torontocannabisauthority.ca/","name":"Home"}},{"@type":"ListItem","position":2,"item":{"@id":"https://torontocannabisauthority.ca/near-me/#webpage"}}]}]}

  return (
    <MainLayout>
      {seo ? (
        <Seo
          title={seo.metaTitle}
          description={seo.metaDescription}
          keywords={seo.keywords}
          schemaMarkup={SEOSchema}
        />
      ) : null}
      <NearMeMap data={map} />
      <NearMeInfo data={nearMeInfo} />
      <FaqBanner data={{ faqSection, shopNowBanner }} />
    </MainLayout>
  );
};

export default FaqPage;

const NearMeQuery = () => {
  const data = useStaticQuery(
    graphql`
      query NearMeQuery {
        strapiNearMePage {
            seo {
                metaDescription
                metaTitle
                keywords
            }
          map {
            title
            subtitle
            text
            map {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            button {
              href
              text
            }
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
          nearMeInfo {
            title
            text
            id
            image {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          faqSection {
            title
            faqItems {
              answer
              question
              id
            }
          }
          shopNowBanner {
            title
            description
            button {
              href
              text
            }
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    `
  );

  const {
    strapiNearMePage: {
      map,
      nearMeInfo,
      faqSection,
      shopNowBanner,
    }
  } = data;

  return {
    map,
    nearMeInfo,
    faqSection,
    shopNowBanner,
  };
}
