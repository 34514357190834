// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  padding: 105px 0 145px;
  background-color: ${Colors.mineralGreen};

  @media (max-width: ${tablet}) {
    padding: 40px 0 105px;
  }
`;

export {
  Wrapper,
}