import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
// Layouts
import Container from '../../layouts/Container';
// Style
import styled from 'styled-components';
import { tablet } from "../../../assets/styles/Responsive";
import {
  Wrapper,
  Head1,
  Head4,
  Text,
  Map,
  Btn,
} from './styled';

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const NearMeMap = ({
  data: {
    title,
    subtitle,
    text,
    map,
    button,
    background
  },
  className,
}) => {

    const imageData = getImage(background?.localFile?.childImageSharp?.gatsbyImageData)
    const bgimage = convertToBgImage(imageData);

  return (
    <BackgroundImage
      Tag="section" 
      className={className}
      alt={title}
      {...bgimage}
    >
      <Container>
        <Wrapper>
          <Head1 value={title} />
          <Head4 value={subtitle} />
          <Text>{text}</Text>
          <Map>
            <GatsbyImage image={map.localFile?.childImageSharp?.gatsbyImageData} alt={map.name} />
          </Map>
          <Btn value={button.text} href={button.href} sm="true" />
        </Wrapper>
      </Container>
    </BackgroundImage>
  );
};

NearMeMap.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

const NearMe = styled(NearMeMap)`
  padding: 170px 0 120px;
  background-size: cover;
  background-position: center bottom;

  @media (max-width: ${tablet}) {
    padding: 120px 0 40px;
  }
`;

export default NearMe;
