import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Style
import {
  Wrapper,
  Description,
  Head2,
  Text,
  Media,
  MediaCard,
} from './styled';

const NearMeInfoItem = ({ data: {
  title, text, image,
}, isreverse }) => {
  return (
    <Wrapper isreverse={isreverse}>
      <Description isreverse={isreverse}>
        <Head2 value={title} />
        <Text>{text}</Text>
      </Description>
      <Media>
        <MediaCard>
          <GatsbyImage image={image.localFile?.childImageSharp?.gatsbyImageData} alt={image.name} />
        </MediaCard>
      </Media>
    </Wrapper>
  );
};

NearMeInfoItem.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default NearMeInfoItem;
